import {AsyncPipe} from '@angular/common';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ButtonModule} from '@aztrix/components/button';
import {SuccessIconModule} from '@aztrix/components/success-icon';
import {TranslatePipe} from '@aztrix/translate';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'ax-verification-success-step',
  templateUrl: './verification-success-step.component.html',
  styleUrls: ['./verification-success-step.component.scss', '../../form.scss'],
  standalone: true,
  imports: [SuccessIconModule, ButtonModule, AsyncPipe, TranslatePipe],
})
export class VerificationSuccessStepComponent {
  @Output() continue = new EventEmitter<void>();
  @Input() loading$: BehaviorSubject<boolean>;

  _continue() {
    this.continue.next();
  }
}
