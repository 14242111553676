import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {AutofocusModule} from '@aztrix/components/autofocus';
import {ButtonModule} from '@aztrix/components/button';
import {TranslatePipe} from '@aztrix/translate';
import {IconsModule} from '@aztrix/icons';

import {PasswordEditComponent} from './password-edit.component';

@NgModule({
  declarations: [PasswordEditComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IconsModule,
    MatFormFieldModule,
    MatInputModule,
    TranslatePipe,
    AutofocusModule,
    ButtonModule,
  ],
  exports: [PasswordEditComponent],
})
export class PasswordEditModule {}
