import {LowerCasePipe} from '@angular/common';
import {Component, Input} from '@angular/core';
import {OtpVerificationModule} from '@aztrix/components/otp-verification';
import {PasswordEditModule} from '@aztrix/components/password-edit';
import {TranslatePipe} from '@aztrix/translate';
import {Observable} from 'rxjs';

import {AbstractResetPasswordStep} from '../abstract-reset-password-step';

@Component({
  selector: 'ax-reset-password-new-password-step',
  templateUrl: './reset-password-new-password-step.component.html',
  styleUrls: ['./reset-password-new-password-step.component.scss'],
  standalone: true,
  imports: [OtpVerificationModule, PasswordEditModule, LowerCasePipe, TranslatePipe],
})
export class ResetPasswordNewPasswordStepComponent extends AbstractResetPasswordStep {
  @Input() verification: (code: string, verificationValue: string) => Observable<unknown>;
  @Input() retryVerification: (verificationValue: string) => Observable<unknown>;
}
