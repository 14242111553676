import {NgFor} from '@angular/common';
import {Component, Input} from '@angular/core';
import {ContrastModule} from '@aztrix/components/contrast';
import {TranslatePipe} from '@aztrix/translate';
import {IconComponent} from '@aztrix/icons';

@Component({
  selector: 'ax-auth-form-header',
  templateUrl: './auth-form-header.component.html',
  styleUrls: ['./auth-form-header.component.scss'],
  standalone: true,
  imports: [NgFor, ContrastModule, IconComponent, TranslatePipe],
})
export class AuthFormHeaderComponent {
  @Input() title: string;
  @Input() subTexts: string[];
  @Input() logo = 'custom:aztrixlogo';
}
