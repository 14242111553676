import {Component} from '@angular/core';
import {DatePropertyEditModule} from '@aztrix/components/property-edit';
import {REGISTER_MINIMUM_AGE} from '@aztrix/helpers';
import {PropertyRepresentation} from '@aztrix/sdk';
import {subYears} from 'date-fns';

import {StepSwitchComponent} from '../../step-switch/step-switch.component';
import {AbstractRegisterStep} from '../abstract-register-step';

@Component({
  selector: 'ax-register-user-data-step',
  templateUrl: './register-user-data-step.component.html',
  styleUrls: ['./register-user-data-step.component.scss', '../../form.scss'],
  standalone: true,
  imports: [DatePropertyEditModule, StepSwitchComponent],
})
export class RegisterUserDataStepComponent extends AbstractRegisterStep {
  MAX_DATE = subYears(new Date(), REGISTER_MINIMUM_AGE);
  REGISTER_MINIMUM_AGE = REGISTER_MINIMUM_AGE;

  get birthdayForm() {
    return this.propertiesForm?.get(PropertyRepresentation.TypeEnum.BIRTHDAY);
  }
}
