<span
  [innerHTML]="
    'agreement.verification.snackbar.message'
      | translate
        : {
            verificationType: showMobile
              ? ('agreement.verification.type.MOBILE' | translate | lowercase)
              : ('agreement.verification.type.EMAIL' | translate | lowercase),
            verificationValue: usernameForm?.value
          }
  "
></span>

@if (passwordForm) {
  <ax-otp-verification
    class="verification"
    [verificationValue]="usernameForm?.value"
    verificationType="EMAIL"
    [verification]="verification"
    [retryVerification]="retryVerification"
    (verificationComplete)="_continue()"
  >
    <ax-password-edit
      [form]="passwordForm"
      [label]="'register.individual.credentials.password.title' | translate"
      [showToggle]="true"
      autocomplete="new-password"
    ></ax-password-edit>
  </ax-otp-verification>
}
