<mat-form-field
  class="c-input"
  [ngClass]="{'remove-subtext': !forgotPasswordHint && !hint && !errors.length}"
  appearance="outline"
  style="width: 100%"
>
  <mat-label>{{ label || ('label.password' | translate) }}</mat-label>
  <input
    matInput
    axAutofocus
    [autofocus]="autofocus"
    [autocomplete]="saveInPasswordManager ? autocompleteAttr ?? 'current-password' : ''"
    [type]="passwordVisible || !saveInPasswordManager ? 'text' : 'password'"
    [ngStyle]="{
      '-webkit-text-security': passwordVisible || saveInPasswordManager ? 'none' : 'disc'
    }"
    [formControl]="form"
  />

  <button
    class="password-toggle"
    *ngIf="showToggle"
    axButton
    matSuffix
    icon-button
    type="button"
    (click)="passwordVisible = !passwordVisible"
  >
    <ax-icon
      class="icon"
      matSuffix
      [ariaLabel]="(passwordVisible ? 'label.hide' : 'label.show') | translate"
      [name]="passwordVisible ? 'eye' : 'eye-off'"
    ></ax-icon>
  </button>
  <mat-error *ngFor="let error of errors">
    <ax-icon name="alert-circle"></ax-icon>
    <span>{{ errorTranslationKey(error) | translate: {amount: minLength} }}</span>
  </mat-error>
  <mat-hint align="end" class="forgot-password-hint" *ngIf="forgotPasswordHint && hint">
    <button
      class="forgot-password-button"
      axButton
      color="primary"
      type="button"
      (click)="openForgotPasswordModal()"
    >
      {{ hint }}
    </button>
  </mat-hint>
  <mat-hint align="end" *ngIf="!forgotPasswordHint && hint">
    <span>{{ hint }}</span>
  </mat-hint>
</mat-form-field>
