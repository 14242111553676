<ax-property-edit
  [form]="firstNameForm"
  name="register firstName"
  [required]="true"
></ax-property-edit>
<ax-property-edit
  [form]="lastNameForm"
  name="register lastName"
  [required]="true"
></ax-property-edit>

<ax-step-switch [loading$]="loading$" (continue)="_continue()" (back)="_back()"></ax-step-switch>
