<div class="value">
  <ax-value-edit
    class="input"
    *ngIf="!showMobile"
    [form]="emailForm"
    [label]="'EMAIL' | propertyTypeLabel"
    type="EMAIL"
    name="login username"
    [errorsTemplate]="errorsTemplate"
    [autofocus]="true"
  ></ax-value-edit>
  <ax-value-edit
    class="input"
    *ngIf="showMobile"
    [form]="mobileForm"
    [label]="'PHONE' | propertyTypeLabel"
    type="PHONE"
    name="login username"
    [errorsTemplate]="errorsTemplate"
    [autofocus]="true"
  ></ax-value-edit>
  <button class="link" axButton type="button" (click)="switchMobileOrEmail()">
    {{ 'login.use-' + (showMobile ? 'email' : 'mobile') | translate }}
  </button>
</div>

<ax-button filled fullWidth class="action" type="button" color="accent" (click)="_continue()">
  {{ 'modal.forgotPassword.button' | translate }}
</ax-button>

<ng-template #errorsTemplate>
  <ax-login-value-form-error
    *ngIf="emailForm.touched && !showMobile"
    [form]="emailForm"
    [propertyType]="'EMAIL'"
  ></ax-login-value-form-error>
  <ax-login-value-form-error
    *ngIf="mobileForm.touched && showMobile"
    [form]="mobileForm"
    [propertyType]="'MOBILE_PHONE'"
  ></ax-login-value-form-error>
</ng-template>
