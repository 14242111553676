import {NgIf} from '@angular/common';
import {Component} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ButtonModule} from '@aztrix/components/button';
import {LoginErrorsModule} from '@aztrix/components/login-errors';
import {LoginValueFormErrorModule} from '@aztrix/components/login-value-form-error';
import {ValueEditModule} from '@aztrix/components/value-edit';
import {TranslatePipe, TranslateService} from '@aztrix/translate';
import {ErrorType} from '@aztrix/models';
import {SessionsService} from '@aztrix/sdk';
import {first} from 'rxjs';

import {ModeService} from '../../../services/mode.service';
import {AbstractOtpLogin} from '../abstract-otp-login-form';
import {HelperPipesModule} from '@aztrix/helpers';

@Component({
  selector: 'ax-otp-login-email-step',
  templateUrl: './otp-login-email-step.component.html',
  styleUrls: ['./otp-login-email-step.component.scss', '../../form.scss'],
  standalone: true,
  imports: [
    LoginErrorsModule,
    ValueEditModule,
    ButtonModule,
    NgIf,
    LoginValueFormErrorModule,
    HelperPipesModule,
    TranslatePipe,
  ],
})
export class OtpLoginEmailStepComponent extends AbstractOtpLogin {
  errorType = ErrorType.NONE;

  constructor(
    private _sessions: SessionsService,
    private _modeService: ModeService,
    private _snackbar: MatSnackBar,
    private _translate: TranslateService
  ) {
    super();
  }

  continueClicked() {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      this.errorType = ErrorType.NONE;
      this._sessions
        .requestOtpLogin({loginValue: this.form.value})
        .pipe(first())
        .subscribe({
          next: () => {
            this._continue();
          },
          error: (response) => {
            if (response.error === ErrorType.LOGIN_NOT_VERIFIED) {
              this._snackbar.open(this._translate.instant('error.login.not-verified'), undefined, {
                duration: 5000,
              });
              this._modeService.setMode('verification', {
                verificationValue: this.form.value,
              });
            } else {
              this.errorType = response.error;
            }
          },
        });
    }
  }
}
