<span
  [innerHTML]="
    'agreement.verification.snackbar.message'
      | translate
        : {
            verificationType: 'agreement.verification.type.EMAIL' | translate | lowercase,
            verificationValue: verificationValue$ | async
          }
  "
></span>

<ax-otp-verification
  class="verification"
  [verificationValue]="verificationValue$ | async"
  verificationType="EMAIL"
  [verification]="verification.verification"
  [retryVerification]="verification.retryVerification"
  [canChangeValue]="false"
  (verificationComplete)="completed.next($event)"
></ax-otp-verification>
