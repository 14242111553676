import {Component} from '@angular/core';
import {PropertyEditModule} from '@aztrix/components/property-edit';
import {ValueEditModule} from '@aztrix/components/value-edit';
import {TranslatePipe} from '@aztrix/translate';
import {PropertyType} from '@aztrix/models';

import {StepSwitchComponent} from '../../step-switch/step-switch.component';
import {AbstractRegisterStep} from '../abstract-register-step';

@Component({
  selector: 'ax-register-organisation-step',
  templateUrl: './register-organisation-step.component.html',
  styleUrls: ['./register-organisation-step.component.scss', '../../form.scss'],
  standalone: true,
  imports: [PropertyEditModule, ValueEditModule, StepSwitchComponent, TranslatePipe],
})
export class RegisterOrganisationStepComponent extends AbstractRegisterStep {
  get organisationNameForm() {
    return this.propertiesForm?.get(PropertyType.ORGANISATION_NAME);
  }

  get legalIdForm() {
    return this.propertiesForm?.get(PropertyType.LEGAL_ID);
  }

  get legalRepresentativeForm() {
    return this.form.get('legalRepresentative');
  }

  get lrRoleForm() {
    return this.legalRepresentativeForm?.get('role');
  }

  get legalRepresentativePropertiesForm() {
    return this.legalRepresentativeForm?.get('properties');
  }

  get lrFirstNameForm() {
    return this.legalRepresentativePropertiesForm?.get(PropertyType.FIRST_NAME);
  }

  get lrLastNameForm() {
    return this.legalRepresentativePropertiesForm?.get(PropertyType.LAST_NAME);
  }

  get lrMobileForm() {
    return this.legalRepresentativePropertiesForm?.get(PropertyType.MOBILE_PHONE);
  }
}
