<ax-button
  class="action back"
  axButton
  color="accent"
  fullWidth
  [loading]="loading$ | async"
  icon="arrow-left"
  (click)="back.next()"
  >{{ 'label.back' | translate }}</ax-button
>

<ax-button
  class="action continue"
  axButton
  color="accent"
  filled
  fullWidth
  [loading]="loading$ | async"
  (click)="continue.next()"
  >{{ 'label.continue' | translate }}</ax-button
>
