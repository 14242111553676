<ng-template #errorsTemplate>
  <div class="c-property-edit__error" *ngIf="alistNameForm.errors?.alistExists">
    <ax-icon class="icon" name="alert-circle"></ax-icon>
    <span>{{
      'register.error.alreadyExists' | translate: {name: alistNameForm.errors.alistExists}
    }}</span>
  </div>
  <div class="c-property-edit__error" *ngIf="alistNameForm.errors?.required">
    <ax-icon class="icon" name="alert-circle"></ax-icon>
    <span>{{
      'property.validation.required' | translate: {propertyType: 'label.name' | translate}
    }}</span>
  </div>
  <div class="c-property-edit__error" *ngIf="alistNameForm.errors?.propertyNoSpace">
    <ax-icon class="icon" name="alert-circle"></ax-icon>
    <span>{{
      'property.validation.invalidCharacters.space'
        | translate: {propertyType: 'label.name' | translate}
    }}</span>
  </div>
  <div class="c-property-edit__error" *ngIf="alistNameForm.errors?.propertyInvalidCharacters">
    <ax-icon class="icon" name="alert-circle"></ax-icon>
    <span>{{
      'property.validation.invalidCharacters.contain'
        | translate: {propertyType: 'label.name' | translate}
    }}</span>
  </div>
  <div class="c-property-edit__error" *ngIf="alistNameForm.errors?.propertyMaxCharacters">
    <ax-icon class="icon" name="alert-circle"></ax-icon>
    <span>{{
      'property.validation.maxCharacters' | translate: {propertyType: 'label.name' | translate}
    }}</span>
  </div>
</ng-template>

<p class="explanation">{{ 'register.claim.explanation' | translate }}</p>

<ax-value-edit
  class="input"
  *ngIf="claim === 'alistname'"
  type="TEXT"
  [form]="alistNameForm"
  name="register list-name"
  [required]="true"
  [prefix]="(alistnamePrefix$ | async) || undefined"
  postfix="*"
  [label]="'label.name' | translate"
  [errorsTemplate]="errorsTemplate"
></ax-value-edit>

<ax-property-edit
  class="input"
  *ngIf="claim === 'username'"
  [form]="usernameForm"
  name="register username"
  [required]="true"
  [prefix]="(usernamePrefix$ | async) || undefined"
  postfix="*"
></ax-property-edit>

<ax-button
  class="action"
  axButton
  color="accent"
  filled
  fullWidth
  (click)="_continue()"
  [loading]="pending$ | async"
  >{{ 'login.create-account' | translate }}</ax-button
>
