import {AsyncPipe} from '@angular/common';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ButtonModule} from '@aztrix/components/button';
import {TranslatePipe} from '@aztrix/translate';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'ax-step-switch',
  templateUrl: './step-switch.component.html',
  styleUrls: ['./step-switch.component.scss', '../form.scss'],
  standalone: true,
  imports: [ButtonModule, AsyncPipe, TranslatePipe],
})
export class StepSwitchComponent {
  @Input() loading$ = new BehaviorSubject<boolean>(false);
  @Output() continue = new EventEmitter<void>();
  @Output() back = new EventEmitter<void>();
}
