export class ClearAuth {
  static readonly type = '[auth] clear';
}

export class Logout {
  static readonly type = '[auth] logout';
}

export class SetAuthToken {
  static readonly type = '[auth] set authToken';
  constructor(public authToken: string) {}
}

export class CheckAuthenticated {
  static readonly type = '[auth] check';
}

export class InvalidateSession {
  static readonly type = '[auth] invalidate session';
}
