<ax-login-errors [errorType]="errorType"></ax-login-errors>

<ax-value-edit
  class="input"
  [label]="'EMAIL' | propertyTypeLabel"
  [form]="form"
  type="EMAIL"
  name="login"
  [errorsTemplate]="errorsTemplate"
  [autofocus]="true"
></ax-value-edit>

<ax-button filled fullWidth class="action" type="button" color="accent" (click)="continueClicked()">
  {{ 'label.continue' | translate }}
</ax-button>

<ng-template #errorsTemplate>
  <ax-login-value-form-error
    *ngIf="form.touched"
    [form]="form"
    [propertyType]="'EMAIL'"
  ></ax-login-value-form-error>
</ng-template>
