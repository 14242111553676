import {Component, Input} from '@angular/core';
import {ErrorType} from '@aztrix/models';

@Component({
  selector: 'ax-login-errors',
  templateUrl: 'login-errors.component.html',
})
export class LoginErrorsComponent {
  @Input() errorType = ErrorType.NONE;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  ErrorType = ErrorType;

  get errorText() {
    switch (this.errorType) {
      case ErrorType.NONE:
        return null;
      case ErrorType.FORBIDDEN:
      case ErrorType.TOO_MANY_VERIFICATION_ATTEMPTS:
        return 'error.too-many-attempts';
      case ErrorType.INVALID_LOGIN:
      case ErrorType.LOGIN_NOT_REGISTERED:
        return 'error.login.invalid.login';
      case ErrorType.LOGIN_INVALID_PASSWORD:
        return 'error.login.invalid.password';
      case ErrorType.LOGIN_NOT_VERIFIED:
        return 'error.login.not-verified';
      case ErrorType.INVALID_TOKEN:
        return 'register.invalid.token';
      default:
        return 'error.default.title';
    }
  }
}
