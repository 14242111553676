import {Inject, Injectable, Optional} from '@angular/core';
import {EnvironmentService} from '@aztrix/environment';
import {countryCodeRep, CountryCodeRepresentation, DEFAULT_COUNTRY} from '@aztrix/helpers';
import {Locale} from '@aztrix/models';
import {SettingsService} from '@aztrix/sdk';
import {Store} from '@ngxs/store';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  map,
  NEVER,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';

import {WEB_ID_ENABLED} from '../web_id_enabled';
import {Init} from './actions';
import {AuthState} from './auth.state';
import {LOCALE, TranslateService} from '@aztrix/translate';

@Injectable({providedIn: 'root'})
export class AuthSettingsService {
  authToken$ = this._store.select(AuthState.authToken);

  loggedIn$ = this.authToken$.pipe(
    map((authToken) => !!authToken),
    shareReplay(1)
  );

  constructor(
    private _store: Store,
    private _environmentService: EnvironmentService,
    private _settings: SettingsService,
    @Optional() private _translate: TranslateService,
    @Inject(WEB_ID_ENABLED)
    private _webIdEnabled: BehaviorSubject<CountryCodeRepresentation>,
    @Optional()
    @Inject(DEFAULT_COUNTRY)
    private _defaultCountry$: BehaviorSubject<CountryCodeRepresentation>,
    @Optional() @Inject(LOCALE) private _locale$: BehaviorSubject<Locale>
  ) {}

  init() {
    combineLatest([
      this.loggedIn$.pipe(filter((loggedIn) => !!loggedIn)),
      this._environmentService.ready$,
    ])
      .pipe(
        tap(() => this._store.dispatch(Init)),
        switchMap((loggedIn) => {
          if (this._webIdEnabled || !loggedIn) {
            return NEVER;
          } else {
            return this._settings.getAccountSettings();
          }
        })
      )
      .subscribe((settings) => {
        this._defaultCountry$.next(countryCodeRep(settings.countryCode));
        if (this._translate) {
          this._translate.setActiveLang(settings.languageCode);
        }
        if (this._locale$) {
          this._locale$.next(<Locale>{
            countryCode: settings.countryCode,
            languageCode: settings.languageCode,
          });
        }
      });
  }
}
