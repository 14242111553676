@switch (mode$ | async) {
  @case ('register') {
    <ax-register-form
      [form]="registerForm"
      [claim]="claim"
      [logo]="logo"
      [alistClaimCode]="alistClaimCode"
      [individualRegisterAllowed]="individualRegisterAllowed"
    ></ax-register-form>
  }
  @case ('reset') {
    <ax-reset-password-form
      [form]="resetPassword"
      [logo]="logo"
      [loginValue]="resetPasswordEmail"
    ></ax-reset-password-form>
  }
  @case ('verification') {
    <ax-verification-form
      [logo]="logo"
      (loginComplete)="loginComplete.emit($event)"
      (registerComplete)="registerCompleted()"
    ></ax-verification-form>
  }
  @default {
    <ax-login-form
      *ngIf="(loginMethod$ | async) === 'password'"
      [form]="loginForm"
      [allowMobile]="allowMobile"
      [logo]="logo"
      (loginComplete)="loginComplete.emit($event)"
    ></ax-login-form>
    <ax-otp-login-form
      *ngIf="(loginMethod$ | async) === 'otp'"
      [form]="otpLoginForm"
      [logo]="logo"
      (loginComplete)="loginComplete.emit($event)"
    ></ax-otp-login-form>
    <ax-webid-login-form
      *ngIf="(loginMethod$ | async) === 'webid'"
      [logo]="logo"
    ></ax-webid-login-form>
  }
}
