import {
  Inject,
  Injectable,
  isDevMode,
  ModuleWithProviders,
  NgModule,
  Optional,
} from '@angular/core';
import {BACKEND_URL, BASE_URL} from '@aztrix/environment';
import {AUTH_TOKEN} from '@aztrix/sdk';
import {BehaviorSubject} from 'rxjs';

import {ApiModule} from './lib';
import {Configuration} from './lib/configuration';

@Injectable({
  providedIn: 'root',
})
export class ApiConfigurationService {
  private _configuration = new Configuration();

  constructor(
    @Inject(AUTH_TOKEN) private _authToken: BehaviorSubject<string | undefined>,
    @Inject(BASE_URL) private _baseUrl: string,
    @Optional() @Inject(BACKEND_URL) private _backendUrl: BehaviorSubject<string>
  ) {
    this._authToken.subscribe((authToken) => {
      this._setAuthToken(authToken);
    });
    if (this._backendUrl) {
      this._backendUrl.subscribe((backendUrl) => {
        this._setBasePath(backendUrl);
      });
    } else {
      this._setBasePath(this._baseUrl);
    }
  }

  private _setAuthToken(authToken: string | undefined) {
    this._configuration.credentials = authToken ? {authToken} : {};
  }

  private _setBasePath(baseUrl: string) {
    if (baseUrl === '' && !isDevMode()) {
      if (location.origin.includes('platform.develop.')) {
        baseUrl = 'https://develop.aztrix.me';
      } else if (location.origin.includes('platform.staging.')) {
        baseUrl = 'https://staging.aztrix.me';
      } else if (location.origin.includes('platform.sandbox.')) {
        baseUrl = 'https://sandbox.aztrix.me';
      } else if (location.origin.includes('platform.')) {
        baseUrl = 'https://aztrix.me';
      }
    }

    this._configuration.basePath = `${baseUrl}/r`;
  }

  configuration(): Configuration {
    return this._configuration;
  }
}

export function apiConfigFactory(service: ApiConfigurationService) {
  return service.configuration();
}

@NgModule({
  imports: [ApiModule],
  providers: [
    ApiConfigurationService,
    {
      provide: Configuration,
      useFactory: apiConfigFactory,
      deps: [ApiConfigurationService],
    },
  ],
})
export class SdkEsgModule {
  static forRoot(baseUrl: string): ModuleWithProviders<SdkEsgModule> {
    return {
      ngModule: SdkEsgModule,
      providers: [
        {
          provide: AUTH_TOKEN,
          useValue: new BehaviorSubject(undefined),
        },
        {
          provide: BASE_URL,
          useValue: baseUrl,
        },
      ],
    };
  }
}
