<ax-success-icon></ax-success-icon>
<p>{{ 'property.verification.modal.verified.title' | translate }}</p>

<ax-button
  class="action"
  filled
  fullWidth
  type="submit"
  color="accent"
  [loading]="loading$ | async"
  (click)="_continue()"
>
  {{ 'label.continue' | translate }}
</ax-button>
