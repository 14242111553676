import {AsyncPipe, NgIf} from '@angular/common';
import {Component, Input, OnInit} from '@angular/core';
import {ButtonModule} from '@aztrix/components/button';
import {PropertyEditModule} from '@aztrix/components/property-edit';
import {ValueEditModule} from '@aztrix/components/value-edit';
import {EnvironmentService} from '@aztrix/environment';
import {TranslatePipe} from '@aztrix/translate';
import {IconComponent} from '@aztrix/icons';
import {PropertyRepresentation} from '@aztrix/sdk';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {BehaviorSubject, map, startWith} from 'rxjs';

import {AbstractRegisterStep} from '../abstract-register-step';

@UntilDestroy()
@Component({
  selector: 'ax-register-claim-step',
  templateUrl: './register-claim-step.component.html',
  styleUrls: ['./register-claim-step.component.scss', '../../form.scss'],
  standalone: true,
  imports: [
    NgIf,
    IconComponent,
    ValueEditModule,
    PropertyEditModule,
    ButtonModule,
    AsyncPipe,
    TranslatePipe,
  ],
})
export class RegisterClaimStepComponent extends AbstractRegisterStep implements OnInit {
  @Input() claim: 'username' | 'alistname' = 'username';

  pending$ = new BehaviorSubject(false);

  usernamePrefix$ = this._env.string$('PLATFORM_URI').pipe(map((uri) => uri + '/'));
  alistnamePrefix$ = this._env.string$('ALIST_VIEW_URI').pipe(map((uri) => uri + '/'));

  constructor(private _env: EnvironmentService) {
    super();
  }

  ngOnInit() {
    this.alistNameForm?.statusChanges
      .pipe(
        startWith('VALID'),
        map((status) => status === 'PENDING'),
        untilDestroyed(this)
      )
      .subscribe((pending: boolean) => {
        this.pending$.next(pending);
      });
  }

  get alistNameForm() {
    return this.form.get('alistName');
  }

  get usernameForm() {
    return this.propertiesForm?.get(PropertyRepresentation.TypeEnum.USERNAME);
  }
}
