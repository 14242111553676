import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {ButtonModule} from '@aztrix/components/button';
import {SnackbarModule} from '@aztrix/components/snackbar';
import {TranslatePipe} from '@aztrix/translate';

import {LoginErrorsComponent} from './login-errors.component';

@NgModule({
  imports: [CommonModule, SnackbarModule, TranslatePipe, ButtonModule, RouterModule],
  exports: [LoginErrorsComponent],
  declarations: [LoginErrorsComponent],
})
export class LoginErrorsModule {}
