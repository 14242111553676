<mat-radio-group class="radios" [formControl]="selectionForm">
  <mat-radio-button
    class="radio"
    [ngClass]="{selected: selectionForm.value === option}"
    *ngFor="let option of options"
    [value]="option"
  >
    {{ 'property.organisation-type.' + option | translate }}
  </mat-radio-button>
</mat-radio-group>

<span class="account-type-context">{{
  'register.account-type.' + selectionForm.value + '.explanation' | translate
}}</span>

<ax-step-switch (continue)="_continue()" (back)="_back()"></ax-step-switch>
