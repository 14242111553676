import {Component} from '@angular/core';
import {PropertyEditModule} from '@aztrix/components/property-edit';
import {PropertyType} from '@aztrix/models';

import {StepSwitchComponent} from '../../step-switch/step-switch.component';
import {AbstractRegisterStep} from '../abstract-register-step';

@Component({
  selector: 'ax-register-individual-step',
  templateUrl: './register-individual-step.component.html',
  styleUrls: ['./register-individual-step.component.scss', '../../form.scss'],
  standalone: true,
  imports: [PropertyEditModule, StepSwitchComponent],
})
export class RegisterIndividualStepComponent extends AbstractRegisterStep {
  get firstNameForm() {
    return this.propertiesForm?.get(PropertyType.FIRST_NAME);
  }

  get lastNameForm() {
    return this.propertiesForm?.get(PropertyType.LAST_NAME);
  }
}
