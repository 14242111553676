import {NgSwitch, NgSwitchCase} from '@angular/common';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatomoService, RegisterStepsCompletedType} from '@aztrix/helpers';
import {SessionsService} from '@aztrix/sdk';
import {BehaviorSubject, first, map} from 'rxjs';

import {ModeService} from '../../services/mode.service';
import {AuthFormHeaderComponent} from '../auth-form-header/auth-form-header.component';
import {VerificationStepComponent} from './verification-step/verification-step.component';
import {VerificationSuccessStepComponent} from './verification-success-step/verification-success-step.component';
import {TranslatePipe} from '@aztrix/translate';

@Component({
  selector: 'ax-verification-form',
  templateUrl: './verification-form.component.html',
  styleUrls: ['./verification-form.component.scss', '../form.scss'],
  standalone: true,
  imports: [
    AuthFormHeaderComponent,
    NgSwitch,
    NgSwitchCase,
    VerificationStepComponent,
    VerificationSuccessStepComponent,
    TranslatePipe,
  ],
})
export class VerificationFormComponent implements OnInit {
  @Input() logo = 'custom:aztrixlogo';
  @Output() loginComplete = new EventEmitter<{authToken: string | undefined}>();
  @Output() registerComplete = new EventEmitter();

  step = 0;
  verificationValue: string;

  loading$ = new BehaviorSubject(false);

  constructor(
    private _mode: ModeService,
    private _matomo: MatomoService,
    private _sessions: SessionsService
  ) {}

  ngOnInit(): void {
    this._matomo.trackEvent('register', RegisterStepsCompletedType.VERIFICATION);
  }

  verificationCompleted(verificationValue: string) {
    this.verificationValue = verificationValue;
    this._mode.setMode('verification', {
      username: verificationValue,
    });
    this.step = 1;
    this._matomo.trackEvent('register', RegisterStepsCompletedType.SUCCESS);
    this.registerComplete.emit();
  }

  continue() {
    this.loading$.next(true);
    if (this._mode.password) {
      this._sessions
        .login({login: this.verificationValue, password: this._mode.password}, 'response')
        .pipe(
          first(),
          map((resp) => resp.headers.get('AuthToken')),
          map((authToken) => authToken || undefined)
        )
        .subscribe({
          next: (authToken) => {
            this._mode.mode$.next('login');
            this.loginComplete.emit({authToken});
          },
          error: () => {
            this._mode.setMode('login', {verificationValue: this.verificationValue});
          },
        });
    } else {
      this._mode.setMode('login', {verificationValue: this.verificationValue});
    }
  }
}
