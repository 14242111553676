<div axContrast>
  @switch (mode$ | async) {
    @case ('register') {
      <span>{{ 'login.has-account' | translate }}</span>
      <button class="link" axButton type="button" (click)="this.modeService.setMode('login')">
        {{ 'label.login' | translate }}
      </button>
    }
    @case ('reset') {
      <button class="link" axButton type="button" (click)="this.modeService.setMode('login')">
        <span>{{ 'reset-password' | translate }}</span>
      </button>
    }
    @default {
      <span>{{ 'login.no-account' | translate }}</span>
      <button class="link" axButton type="button" (click)="this.modeService.setMode('register')">
        {{ 'login.create-account' | translate }}
      </button>
    }
  }
</div>
