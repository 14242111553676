import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {isUnauthorizedError} from '@aztrix/helpers';
import {
  Observable,
  ReplaySubject,
  Subscription,
  combineLatest,
  interval,
  of,
  throwError,
} from 'rxjs';
import {catchError, first, mergeMap, startWith, tap, throttleTime} from 'rxjs/operators';

import {AuthService} from '../store/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor, OnDestroy {
  private _subscription = new Subscription();
  private _checkAuthenticated = new ReplaySubject<void>(1);

  constructor(private authentication: AuthService) {
    this._subscription = combineLatest([
      this._checkAuthenticated,
      interval(20000).pipe(startWith(undefined)),
    ])
      .pipe(
        throttleTime(3000),
        mergeMap(() => this.authentication.checkAuthenticated()),
        tap({
          error: () => {
            this.authentication.invalidateSession();
          },
        }),
        catchError(() => of(undefined))
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.params.has('_noAuth')) {
      req = req.clone({params: req.params.delete('_noAuth')});
      return next.handle(req);
    } else {
      return this.authentication.authToken$.pipe(
        first(),
        mergeMap((authToken) => {
          let alteredReq = req;
          if (authToken) {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            alteredReq = req.clone({setHeaders: {AuthToken: authToken}});
          }
          return next.handle(alteredReq).pipe(
            catchError((err: HttpErrorResponse) => {
              if (isUnauthorizedError(err)) {
                this._checkAuthenticated.next(undefined);
              }
              return throwError(() => err);
            })
          );
        })
      );
    }
  }
}
