import {Injectable} from '@angular/core';
import {PlatformService} from '@aztrix/helpers';
import {SessionsService} from '@aztrix/sdk';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {
  catchError,
  filter,
  from,
  mergeMap,
  Observable,
  of,
  reduce,
  switchMap,
  throwError,
} from 'rxjs';

import {Clear} from './actions';
import {
  CheckAuthenticated,
  ClearAuth,
  InvalidateSession,
  Logout,
  SetAuthToken,
} from './auth.actions';
import {AuthStateModel, defaults} from './auth-state.model';

@State<AuthStateModel>({
  name: 'auth',
  defaults,
})
@Injectable()
export class AuthState {
  constructor(
    private _platform: PlatformService,
    private _sessions: SessionsService
  ) {}

  @Selector()
  static authToken(state: AuthStateModel): string | undefined {
    return state && state.authToken;
  }

  @Action(ClearAuth)
  clearAuth({setState}: StateContext<AuthStateModel>) {
    setState({...defaults});
  }

  @Action(SetAuthToken)
  setAuthToken({patchState}: StateContext<AuthStateModel>, {authToken}: SetAuthToken) {
    patchState({authToken});
  }

  @Action(Logout)
  logout({dispatch}: StateContext<AuthStateModel>) {
    return this._sessions.logout().pipe(
      catchError(() => of(undefined)),
      switchMap(() => dispatch(InvalidateSession))
    );
  }

  @Action(CheckAuthenticated)
  checkAuthenticated({getState, dispatch}: StateContext<AuthStateModel>) {
    if (!getState().authToken) {
      return throwError(() => new Error('no AuthToken'));
    }

    return this._sessions.getLoginHeader().pipe(catchError(() => dispatch(InvalidateSession)));
  }

  @Action(InvalidateSession)
  invalidateSession({dispatch}: StateContext<AuthStateModel>) {
    this._removeWorkerCache().subscribe();
    sessionStorage.clear();
    localStorage.clear();
    return dispatch([Clear, ClearAuth]);
  }

  private _removeWorkerCache(): Observable<any> {
    if (this._platform.isNativeApp()) {
      return of(undefined);
    }
    return from(caches.keys()).pipe(
      mergeMap((keys) => from(keys)),
      filter((key) => key.includes(':data:dynamic:backend:')),
      mergeMap((key) => caches.open(key)),
      mergeMap((cache) =>
        from(cache.keys()).pipe(
          mergeMap((keys) => from(keys)),
          mergeMap((key) => cache.delete(key))
        )
      ),
      reduce(() => void 0, void 0)
    );
  }
}
