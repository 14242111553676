<span
  [innerHTML]="
    'agreement.verification.snackbar.message'
      | translate
        : {
            verificationType: 'agreement.verification.type.EMAIL' | translate | lowercase,
            verificationValue: form.value
          }
  "
></span>

<ax-otp-verification
  class="verification"
  [verificationValue]="form.value"
  verificationType="EMAIL"
  [verification]="verification"
  [retryVerification]="retryVerification"
  (verificationComplete)="_continue()"
></ax-otp-verification>
