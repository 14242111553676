import {Injectable} from '@angular/core';
import {RegisterService} from '@aztrix/sdk';
import {Observable} from 'rxjs';

@Injectable()
export class VerificationService {
  constructor(private _register: RegisterService) {}

  verification = (code: string, verificationValue: string): Observable<any> => {
    return this._register.completeRegistration(verificationValue, code);
  };

  retryVerification = (verificationValue: string): Observable<any> => {
    return this._register.resendRegistrationVerification(verificationValue);
  };
}
