import {NgIf} from '@angular/common';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslatePipe} from '@aztrix/translate';
import {SessionsService} from '@aztrix/sdk';
import {FormControl} from '@ngneat/reactive-forms';
import {first, map, Observable, tap} from 'rxjs';

import {AuthFormHeaderComponent} from '../auth-form-header/auth-form-header.component';
import {VerificationSuccessStepComponent} from '../verification-form/verification-success-step/verification-success-step.component';
import {OtpLoginEmailStepComponent} from './otp-login-email-step/otp-login-email-step.component';
import {OtpLoginVerificationStepComponent} from './otp-login-verification-step/otp-login-verification-step.component';

@Component({
  selector: 'ax-otp-login-form',
  templateUrl: './otp-login-form.component.html',
  styleUrls: ['./otp-login-form.component.scss', '../form.scss'],
  standalone: true,
  imports: [
    AuthFormHeaderComponent,
    NgIf,
    OtpLoginEmailStepComponent,
    OtpLoginVerificationStepComponent,
    VerificationSuccessStepComponent,
    TranslatePipe,
  ],
})
export class OtpLoginFormComponent {
  @Input() form: FormControl<string>;
  @Input() logo = 'custom:aztrixlogo';
  @Output() loginComplete = new EventEmitter<{authToken: string | undefined}>();

  step = 0;

  constructor(private _sessions: SessionsService) {}

  verification = (code: string, verificationValue: string): Observable<any> => {
    return this._sessions.loginWithOtp({login: verificationValue, otp: code}, 'response').pipe(
      first(),
      map((resp) => resp.headers.get('AuthToken')),
      map((authToken) => authToken || undefined),
      tap({
        next: (authToken) => {
          this.loginComplete.next({authToken});
        },
      })
    );
  };

  retryVerification = (verificationValue: string): Observable<any> => {
    return this._sessions.requestOtpLogin({loginValue: verificationValue});
  };

  continue() {
    this.step++;
  }
}
