import {Directive, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from '@ngneat/reactive-forms';

@Directive()
export class AbstractOtpLogin {
  @Input() form: FormControl<string>;
  @Output() back = new EventEmitter<void>();
  @Output() continue = new EventEmitter<void>();

  _continue() {
    this.continue.next();
  }
}
