import {AsyncPipe, LowerCasePipe, NgClass, NgIf, NgSwitch, NgSwitchCase} from '@angular/common';
import {Component, Inject, Input, OnChanges, OnInit, Optional, SimpleChanges} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {SnackbarModule} from '@aztrix/components/snackbar';
import {BASE_URL} from '@aztrix/environment';
import {
  hasValue,
  MatomoService,
  REGISTER_MINIMUM_AGE,
  RegisterStepsCompletedType,
  typeFilter,
} from '@aztrix/helpers';
import {ErrorType, ProfileContext, PropertyType} from '@aztrix/models';
import {PropertyRepresentation, RegisterService} from '@aztrix/sdk';
import {ControlsOf, FormGroup} from '@ngneat/reactive-forms';
import {BehaviorSubject, first, map} from 'rxjs';

import {ModeService} from '../../services/mode.service';
import {AuthFormHeaderComponent} from '../auth-form-header/auth-form-header.component';
import {ModeSwitchComponent} from '../mode-switch/mode-switch.component';
import {RegisterAccountTypeStepComponent} from './register-account-type-step/register-account-type-step.component';
import {RegisterAuthenticationStepComponent} from './register-authentication-step/register-authentication-step.component';
import {RegisterClaimStepComponent} from './register-claim-step/register-claim-step.component';
import {RegisterIndividualStepComponent} from './register-individual-step/register-individual-step.component';
import {RegisterOrganisationStepComponent} from './register-organisation-step/register-organisation-step.component';
import {RegisterUserDataStepComponent} from './register-user-data-step/register-user-data-step.component';
import {TranslatePipe, TranslateService} from '@aztrix/translate';

@Component({
  selector: 'ax-register-form',
  templateUrl: 'register-form.component.html',
  styleUrls: ['../form.scss', './register-form.component.scss'],
  standalone: true,
  imports: [
    NgSwitch,
    NgSwitchCase,
    AuthFormHeaderComponent,
    NgIf,
    SnackbarModule,
    NgClass,
    RegisterClaimStepComponent,
    RegisterAuthenticationStepComponent,
    RegisterUserDataStepComponent,
    RegisterAccountTypeStepComponent,
    RegisterIndividualStepComponent,
    RegisterOrganisationStepComponent,
    ModeSwitchComponent,
    AsyncPipe,
    LowerCasePipe,
    TranslatePipe,
  ],
})
export class RegisterFormComponent implements OnInit, OnChanges {
  @Input() form: FormGroup<ControlsOf<any>>;
  @Input() claim: 'username' | 'alistname' = 'username';
  @Input() alistClaimCode?: string;
  @Input() logo = 'custom:aztrixlogo';
  @Input() individualRegisterAllowed = true;

  loading$ = new BehaviorSubject<boolean>(false);
  error$ = new BehaviorSubject<string | boolean>(false);

  errorMessage$ = this.error$.pipe(
    map((error) => {
      if (!error) {
        return null;
      }
      switch (error) {
        case ErrorType.LOGIN_ALREADY_REGISTERED:
          return 'register.already.exists';
        case ErrorType.REGISTRATION_DISABLED:
          return 'register.disabled';
        case ErrorType.LOGIN_INVALID_PASSWORD:
          return 'error.login.invalid.password';
        default:
          return 'error.default.title';
      }
    })
  );

  step = 0;
  stepContext: RegisterStepsCompletedType = RegisterStepsCompletedType.CLAIM;

  ProfileContext = ProfileContext;
  REGISTER_MINIMUM_AGE = REGISTER_MINIMUM_AGE;

  constructor(
    private _register: RegisterService,
    private _translationService: TranslateService,
    private _mode: ModeService,
    private _route: ActivatedRoute,
    private _matomo: MatomoService,
    @Optional() @Inject(BASE_URL) public baseUrl: string
  ) {}

  ngOnInit() {
    this._matomo.trackEvent('register', RegisterStepsCompletedType.CLAIM);
    this._route.queryParams
      .pipe(
        first(),
        map((queryParams) => queryParams['name'])
      )
      .subscribe((name) => {
        if (name) {
          if (this.claim === 'alistname') {
            this.alistNameForm?.setValue(name);
          } else {
            this.propertiesForm
              ?.get(PropertyRepresentation.TypeEnum.USERNAME)
              ?.get('value')
              ?.setValue(name);
          }
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.alistClaimCode && this.alistClaimCode) {
      this.step = 1;
    }
  }

  get passwordForm() {
    return this.form.get('password');
  }

  get typeForm() {
    return this.form.get('type');
  }

  get alistNameForm() {
    return this.form.get('alistName');
  }

  get legalForm() {
    return this.form.get('legal');
  }

  get propertiesForm() {
    return this.form.get('properties');
  }

  get legalRepresentativeForm() {
    return this.form.get('legalRepresentative');
  }

  back() {
    this.step--;
  }

  continue() {
    this._touch(this.step);
    if (!this._formValid(this.step)) {
      return;
    }

    if (this.step === 4) {
      this._submit();
    } else {
      this.step++;
      this._matomo.trackEvent(
        'register',
        this.stepContext === RegisterStepsCompletedType.ACCOUNT_TYPE
          ? this.stepContext + '_' + this.typeForm?.value
          : this.stepContext
      );
    }
  }

  private _formValid(step: number): boolean {
    return this._stepControls(step).every((form) => form?.valid);
  }

  private _touch(step: number): void {
    this.form.markAsUntouched();
    for (const form of this._stepControls(step)) {
      form?.markAllAsTouched();
    }
  }

  private _stepControls(step: number): (AbstractControl | null | undefined)[] {
    switch (step) {
      case 0:
        return [
          this.claim === 'alistname'
            ? this.alistNameForm
            : this._propertyForm(PropertyRepresentation.TypeEnum.USERNAME),
        ];
      case 1:
        this.stepContext = RegisterStepsCompletedType.AUTHENTICATION;
        return [
          this._propertyForm(PropertyRepresentation.TypeEnum.EMAIL),
          this.passwordForm,
          this.legalForm?.get('terms'),
          this.legalForm?.get('privacy'),
          this.legalForm?.get('newsletter'),
        ];
      case 2:
        this.stepContext = RegisterStepsCompletedType.USER_DATA;
        return [this._propertyForm(PropertyRepresentation.TypeEnum.BIRTHDAY)];
      case 3:
        this.stepContext = RegisterStepsCompletedType.ACCOUNT_TYPE;
        if (this.typeForm?.value === ProfileContext.INDIVIDUAL) {
          return [];
        } else {
          return [this._propertyForm(PropertyRepresentation.TypeEnum.ORGANISATION_TYPE)];
        }
      default:
        this.stepContext = RegisterStepsCompletedType.PROFILE_DATA;
        if (this.typeForm?.value === ProfileContext.INDIVIDUAL) {
          return [
            this._propertyForm(PropertyRepresentation.TypeEnum.FIRST_NAME),
            this._propertyForm(PropertyRepresentation.TypeEnum.LAST_NAME),
          ];
        } else {
          return [
            this._propertyForm(PropertyRepresentation.TypeEnum.ORGANISATION_NAME),
            this._propertyForm(PropertyRepresentation.TypeEnum.LEGAL_ID),
            this.legalRepresentativeForm,
          ];
        }
    }
  }

  private _propertyForm(type: PropertyRepresentation.TypeEnum): AbstractControl | null | undefined {
    return this.propertiesForm?.get(type);
  }

  private _submit() {
    this.loading$.next(true);
    this.error$.next(false);

    if (this.typeForm?.value === ProfileContext.INDIVIDUAL) {
      const propertyTypes = [
        PropertyRepresentation.TypeEnum.EMAIL,
        PropertyRepresentation.TypeEnum.FIRST_NAME,
        PropertyRepresentation.TypeEnum.LAST_NAME,
        PropertyRepresentation.TypeEnum.BIRTHDAY,
      ];

      if (this.claim === 'username') {
        propertyTypes.push(PropertyRepresentation.TypeEnum.USERNAME);
      }

      this._register
        .registerIndividual({
          alistName: this.claim === 'alistname' ? this.alistNameForm?.value : undefined,
          language: this._translationService.getActiveLang(),
          country: 'BE',
          properties: Object.values(<PropertyRepresentation[]>this.form.value.properties)
            .filter(typeFilter(...propertyTypes))
            .filter((property) => hasValue(property)),
          password: this.passwordForm?.value,
          newsletterAccepted: this.legalForm?.get('newsletter')?.value,
          alistClaimCode: this.alistClaimCode,
        })
        .subscribe({
          error: (response) => {
            this.loading$.next(false);
            this.error$.next(typeof response.error === 'string' ? response.error : true);
          },
          next: () => {
            this.loading$.next(false);
            this._mode.password = this.passwordForm?.value;
            this._matomo.trackEvent('register', this.stepContext + '_' + this.typeForm?.value);
            this._mode.setMode('verification', {
              verificationValue: this.propertiesForm?.get(PropertyType.EMAIL)?.get('value')?.value,
            });
          },
        });
    } else {
      const propertyTypes = [
        PropertyRepresentation.TypeEnum.EMAIL,
        PropertyRepresentation.TypeEnum.ORGANISATION_NAME,
        PropertyRepresentation.TypeEnum.ORGANISATION_TYPE,
        PropertyRepresentation.TypeEnum.LEGAL_ID,
      ];

      if (this.claim === 'username') {
        propertyTypes.push(PropertyRepresentation.TypeEnum.USERNAME);
      }

      this._register
        .registerOrganisation({
          alistName: this.claim === 'alistname' ? this.alistNameForm?.value : undefined,
          language: this._translationService.getActiveLang(),
          country: 'BE',
          properties: Object.values(<PropertyRepresentation[]>this.form.value.properties)
            .filter(typeFilter(...propertyTypes))
            .filter((property) => hasValue(property)),
          password: this.passwordForm?.value,
          newsletterAccepted: this.legalForm?.get('newsletter')?.value,
          legalRepresentative: {
            role: this.form.get('legalRepresentative')?.get('role')?.value,
            properties: <PropertyRepresentation[]>(
              Object.values(this.form.get('legalRepresentative')?.get('properties')?.value)
            ),
          },
          alistClaimCode: this.alistClaimCode,
        })
        .subscribe({
          error: (response) => {
            this.loading$.next(false);
            this.error$.next(typeof response.error === 'string' ? response.error : true);
          },
          next: () => {
            this.loading$.next(false);
            this._mode.password = this.passwordForm?.value;
            this._matomo.trackEvent('register', this.stepContext + '_' + this.typeForm?.value);
            this._mode.setMode('verification', {
              verificationValue: this.propertiesForm?.get(PropertyType.EMAIL)?.get('value')?.value,
              profileContext: this.typeForm?.value,
            });
          },
        });
    }
  }
}
