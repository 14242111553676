<ax-property-edit
  [form]="organisationNameForm"
  name="register organisationName"
  [required]="true"
></ax-property-edit>
<ax-property-edit
  [form]="legalIdForm"
  name="register legalID"
  [required]="false"
></ax-property-edit>

<h2>{{ 'register.organisation.legal-representative' | translate }}</h2>
<div>
  <ax-value-edit
    type="TEXT"
    [form]="lrRoleForm"
    name="register role"
    [required]="true"
    [label]="'label.role' | translate"
  ></ax-value-edit>
  <ax-property-edit
    [form]="lrFirstNameForm"
    name="register firstName"
    [required]="true"
  ></ax-property-edit>
  <ax-property-edit
    [form]="lrLastNameForm"
    name="register lastName"
    [required]="true"
  ></ax-property-edit>
  <ax-property-edit
    [form]="lrMobileForm"
    name="register mobile"
    [required]="true"
  ></ax-property-edit>
</div>

<ax-step-switch [loading$]="loading$" (continue)="_continue()" (back)="_back()"></ax-step-switch>
