<ax-auth-form-header
  title="modal.forgotPassword.button"
  [subTexts]="[
    'password.reset.explanation' | translate,
    'password.reset.explanation.2' | translate
  ]"
  [logo]="logo"
></ax-auth-form-header>

<div class="form">
  <ax-snackbar *ngIf="error$ | async" [dismissible]="false" color="red">
    <ng-container [ngSwitch]="error$ | async">
      <span *ngSwitchCase="'FORBIDDEN'">{{ 'error.too-many-attempts' | translate }}</span>
      <span *ngSwitchCase="'LOGIN_NOT_REGISTERED'">{{
        'error.reset.invalid.email' | translate
      }}</span>
      <span *ngSwitchDefault>{{ 'error.default.title' | translate }}</span>
    </ng-container>
  </ax-snackbar>

  <ax-reset-password-email-step
    *ngIf="step === 0"
    [form]="form"
    (back)="back()"
    (continue)="sendResetPasswordRequest()"
  ></ax-reset-password-email-step>
  <ax-reset-password-new-password-step
    *ngIf="step === 1"
    [form]="form"
    [verification]="verification"
    [retryVerification]="retryVerification"
  ></ax-reset-password-new-password-step>
</div>
<ax-button
  *ngIf="step === 0"
  class="action back"
  axButton
  color="accent"
  fullWidth
  type="button"
  (click)="back()"
  icon="arrow-left"
>
  {{ 'label.login.back-to' | translate }}</ax-button
>
