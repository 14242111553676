import {Directive, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CustomValidators} from '@aztrix/helpers';
import {ControlsOf, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {BehaviorSubject} from 'rxjs';

@Directive()
export class AbstractResetPasswordStep implements OnInit {
  @Input() form: FormGroup<ControlsOf<{username: string; password: string}>> | undefined;
  @Input() loading$: BehaviorSubject<boolean>;
  @Output() back = new EventEmitter<void>();
  @Output() continue = new EventEmitter<void>();

  mobileForm = new FormControl('', [CustomValidators.validateLoginValue()]);
  emailForm = new FormControl('', [CustomValidators.validateLoginValue()]);
  showMobile = false;

  ngOnInit(): void {
    this.mobileForm.valueChanges.subscribe((value) => {
      this.usernameForm?.setValue(value);
    });
    this.emailForm.valueChanges.subscribe((value) => {
      this.usernameForm?.setValue(value);
    });
  }

  get usernameForm() {
    return this.form?.get('username');
  }

  get passwordForm() {
    return this.form?.get('password');
  }

  switchMobileOrEmail() {
    if (this.showMobile) {
      this.usernameForm?.setValue(this.emailForm.value);
    } else {
      this.usernameForm?.setValue(this.mobileForm.value);
    }
    this.showMobile = !this.showMobile;
  }

  _back() {
    this.back.next();
  }

  _continue() {
    this.continue.next();
  }
}
