<ax-property-edit
  [form]="emailForm"
  name="register email"
  [required]="true"
  [hint]="'register.email.explanation' | translate"
></ax-property-edit>

<ax-password-edit
  [form]="passwordForm"
  [label]="'register.individual.credentials.password.title' | translate"
  [showToggle]="true"
  autocomplete="new-password"
></ax-password-edit>

<mat-checkbox [formControl]="termsForm">
  <span>
    {{ 'register.individual.credentials.agreements' | translate }}
  </span>
  <a class="legal green-text" [attr.href]="termsUrl$ | async" target="_blank">
    {{ 'label.terms' | translate }}
    <ax-icon class="icon" name="open-in-new"></ax-icon>
  </a>
</mat-checkbox>

<mat-checkbox [formControl]="privacyForm">
  <span>
    {{ 'register.individual.credentials.agreements' | translate }}
  </span>

  <a class="legal green-text" [attr.href]="privacyUrl$ | async" target="_blank">
    {{ 'label.privacy.policy' | translate }}
    <ax-icon class="icon" name="open-in-new"></ax-icon>
  </a>
</mat-checkbox>

<mat-checkbox [formControl]="newsletterForm">{{ 'register.newsletter' | translate }}</mat-checkbox>

<mat-error class="error">
  <div
    class="c-property-edit__error"
    *ngIf="termsForm?.touched && privacyForm?.touched && legalForm?.invalid"
  >
    <ax-icon class="icon" name="alert-circle"></ax-icon>
    <span>{{ 'document.confirmation.required' | translate }}</span>
  </div>
</mat-error>

<ax-button
  *ngIf="!hasBackButton"
  class="action"
  axButton
  color="accent"
  filled
  fullWidth
  (click)="_continue()"
  >{{ 'login.create-account' | translate }}</ax-button
>

<ax-step-switch *ngIf="hasBackButton" (continue)="_continue()" (back)="_back()"></ax-step-switch>
