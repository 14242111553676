import {Directive, EventEmitter, Input, Output} from '@angular/core';
import {ControlsOf, FormGroup} from '@ngneat/reactive-forms';
import {BehaviorSubject} from 'rxjs';

@Directive()
export class AbstractRegisterStep {
  @Input() form: FormGroup<ControlsOf<any>>;
  @Input() loading$: BehaviorSubject<boolean>;
  @Output() back = new EventEmitter<void>();
  @Output() continue = new EventEmitter<void>();

  get propertiesForm() {
    return this.form.get('properties');
  }

  get legalForm() {
    return this.form.get('legal');
  }

  _back() {
    this.back.next();
  }

  _continue() {
    this.continue.next();
  }
}
