import {AsyncPipe, LowerCasePipe} from '@angular/common';
import {Component, EventEmitter, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {OtpVerificationModule} from '@aztrix/components/otp-verification';
import {TranslatePipe} from '@aztrix/translate';
import {map} from 'rxjs';

import {ModeService} from '../../../services/mode.service';
import {VerificationService} from '../../../services/verification.service';

@Component({
  selector: 'ax-verification-step',
  templateUrl: './verification-step.component.html',
  styleUrls: ['./verification-step.component.scss', '../../form.scss'],
  standalone: true,
  imports: [OtpVerificationModule, AsyncPipe, LowerCasePipe, TranslatePipe],
})
export class VerificationStepComponent {
  @Output() completed = new EventEmitter<string>();

  verificationValue$ = this._route.queryParams.pipe(
    map((queryParams) => queryParams['verificationValue'])
  );

  mode$ = this._modeService.mode$;

  constructor(
    public verification: VerificationService,
    private _modeService: ModeService,
    private _route: ActivatedRoute
  ) {}
}
