import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {AutofocusModule} from '@aztrix/components/autofocus';
import {ButtonModule} from '@aztrix/components/button';
import {TranslatePipe} from '@aztrix/translate';
import {IconsModule} from '@aztrix/icons';

import {SearchQueryComponent} from './search-query.component';

@NgModule({
  declarations: [SearchQueryComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IconsModule,
    TranslatePipe,
    AutofocusModule,
    RouterModule,
    ButtonModule,
  ],
  exports: [SearchQueryComponent],
})
export class SearchQueryModule {}
