import {AsyncPipe, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault} from '@angular/common';
import {Component, Input, OnInit} from '@angular/core';
import {ButtonModule} from '@aztrix/components/button';
import {SnackbarModule} from '@aztrix/components/snackbar';
import {TranslatePipe} from '@aztrix/translate';
import {SessionsService} from '@aztrix/sdk';
import {ControlsOf, FormGroup} from '@ngneat/reactive-forms';
import {BehaviorSubject, Observable, tap} from 'rxjs';

import {ModeService} from '../../services/mode.service';
import {AuthFormHeaderComponent} from '../auth-form-header/auth-form-header.component';
import {ResetPasswordEmailStepComponent} from './reset-password-email-step/reset-password-email-step.component';
import {ResetPasswordNewPasswordStepComponent} from './reset-password-new-password-step/reset-password-new-password-step.component';

@Component({
  selector: 'ax-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss', '../form.scss'],
  standalone: true,
  imports: [
    AuthFormHeaderComponent,
    NgIf,
    SnackbarModule,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    ResetPasswordEmailStepComponent,
    ResetPasswordNewPasswordStepComponent,
    ButtonModule,
    AsyncPipe,
    TranslatePipe,
  ],
})
export class ResetPasswordFormComponent implements OnInit {
  @Input() form: FormGroup<ControlsOf<{username: string; password: string}>>;
  @Input() loginValue?: string;
  @Input() logo = 'custom:aztrixlogo';

  error$ = new BehaviorSubject(null);
  step = 0;

  constructor(
    public modeService: ModeService,
    private _sessionsService: SessionsService
  ) {}

  ngOnInit() {
    if (this.loginValue) {
      this.usernameForm?.setValue(this.loginValue);
    }
  }

  get usernameForm() {
    return this.form.get('username');
  }

  get passwordForm() {
    return this.form.get('password');
  }

  back() {
    if (this.step === 0) {
      this.modeService.setMode('login');
    } else {
      this.step = 0;
    }
  }

  sendResetPasswordRequest() {
    if (!this._formValid(this.step)) {
      return;
    }

    this.error$.next(null);
    this._sessionsService.requestPasswordReset({loginValue: this.usernameForm?.value}).subscribe({
      next: () => (this.step = 1),
      error: (err) => {
        this.error$.next(err.error);
      },
    });
  }

  verification = (code: string, verificationValue: string): Observable<unknown> => {
    this.error$.next(null);
    return this._sessionsService
      .confirmResetPassword({
        loginValue: verificationValue,
        code,
        password: this.passwordForm?.value,
      })
      .pipe(
        tap({
          next: () => {
            this.modeService.setMode('login');
          },
          error: (err) => {
            this.error$.next(err.error);
            this.step = 0;
          },
        })
      );
  };

  retryVerification = (verificationValue: string): Observable<unknown> => {
    this.error$.next(null);
    return this._sessionsService.requestPasswordReset({loginValue: verificationValue}).pipe(
      tap({
        error: (err) => {
          this.error$.next(err.error);
          this.step = 0;
        },
      })
    );
  };

  private _formValid(step: number): boolean {
    this.form.markAsUntouched();
    if (step === 0) {
      this.usernameForm?.markAsTouched();
      return this.usernameForm?.valid || false;
    }
    if (step === 1) {
      this.passwordForm?.markAsTouched();
      return this.passwordForm?.valid || false;
    }
    return false;
  }
}
