<div class="modal-content-wrapper">
  <ax-login-errors [errorType]="errorType"></ax-login-errors>
  <form class="c-login__form m-form m-form--no-padding">
    <ax-property-edit [form]="form"></ax-property-edit>
  </form>
  <div class="h-button-wrapper">
    <button
      axButton
      (click)="executeSendAction()"
      color="primary"
      [disabled]="form.invalid || form.disabled"
      filled
    >
      {{ 'modal.forgotPassword.button' | translate }}
    </button>
  </div>
</div>
