<ax-auth-form-header
  title="label.verification"
  [subTexts]="['register.individual.pending.title' | translate]"
  [logo]="logo"
></ax-auth-form-header>

<div class="register-form form" [ngSwitch]="step">
  <ax-verification-step
    *ngSwitchCase="0"
    (completed)="verificationCompleted($event)"
  ></ax-verification-step>
  <ax-verification-success-step
    *ngSwitchCase="1"
    [loading$]="loading$"
    (continue)="continue()"
  ></ax-verification-success-step>
</div>
