<div class="wrapper">
  <ax-icon class="icon" name="magnify"></ax-icon>
  <input
    #input
    axAutofocus
    autocomplete="off"
    type="text"
    class="input"
    [placeholder]="placeholder | translate"
    [autofocus]="autofocus"
    [value]="_value"
    (keyup)="setValue(input.value)"
    (keyup.enter)="searchImmediately()"
    (blur)="_onTouched()"
  />
  <button
    axButton
    icon-button
    class="clear-button"
    aria-label="Clear"
    *ngIf="_value && _value.length"
    (click)="clearValue()"
  >
    <ax-icon class="icon" name="close"></ax-icon>
  </button>
  <ng-container *ngIf="!_value?.length">
    <ng-content></ng-content>
  </ng-container>
</div>
