/**
 * Esg
 * Esg app.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ProductDetailRequest {
  productDetailId?: string;
  ownerWebId?: string;
  requestorWebId?: string;
  status?: ProductDetailRequest.StatusEnum;
}
export namespace ProductDetailRequest {
  export type StatusEnum = 'PENDING' | 'GRANTED';
  export const StatusEnum = {
    PENDING: 'PENDING' as StatusEnum,
    GRANTED: 'GRANTED' as StatusEnum,
  };
}
