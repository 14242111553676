import {NgFor} from '@angular/common';
import {Component, Input, isDevMode} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ButtonModule} from '@aztrix/components/button';
import {TranslatePipe} from '@aztrix/translate';
import {SessionsService} from '@aztrix/sdk-esg';
import {combineLatest, from, map, Observable} from 'rxjs';

import {AuthFormHeaderComponent} from '../auth-form-header/auth-form-header.component';

interface Provider {
  name: string;
  url: string;
  disabled?: boolean;
}

@Component({
  selector: 'ax-webid-login-form',
  templateUrl: './webid-login-form.component.html',
  styleUrls: ['../form.scss', '../link.scss', './webid-login-form.component.scss'],
  standalone: true,
  imports: [AuthFormHeaderComponent, NgFor, ButtonModule, TranslatePipe],
})
export class WebidLoginFormComponent {
  @Input() logo = 'custom:aztrixlogo';

  urlForm = new FormControl();

  private defaultProvider: Provider = {name: 'Inrupt', url: 'https://login.inrupt.com/'};

  mode: 'choose' | 'providers' = 'choose';
  urlType: 'webid' | 'provider' = 'webid';

  providers: Provider[] = [
    this.defaultProvider,
    {name: 'Solid Web', url: 'https://solidweb.me', disabled: true},
    {name: 'Solid Community', url: 'https://solidcommunity.net', disabled: true},
    {name: 'use.id Sandbox', url: 'https://sandbox.idp.use.id', disabled: true},
  ];

  constructor(private _session: SessionsService) {}

  showProviders() {
    this.mode = 'providers';
  }

  switchType() {
    if (this.urlType === 'provider') {
      this.urlType = 'webid';
    } else {
      this.urlType = 'provider';
    }
  }

  initiateUrl() {
    if (!this.urlForm.value || !this.urlForm.valid) {
      return;
    }

    if (this.urlType === 'webid') {
      this.initiate({webIdUrl: this.urlForm.value});
    } else {
      this.initiate({identityProviderUrl: this.urlForm.value});
    }
  }

  initiate({webIdUrl, identityProviderUrl}: {webIdUrl?: string; identityProviderUrl?: string}) {
    if (!webIdUrl && !identityProviderUrl) {
      identityProviderUrl = this.defaultProvider.url;
    }

    const codeVerifier = this._generateCodeVerifier();

    sessionStorage.setItem('aztrix:web-id:code_verifier', codeVerifier);
    if (webIdUrl) {
      sessionStorage.setItem('aztrix:web-id:url', webIdUrl);
    } else {
      sessionStorage.removeItem('aztrix:web-id:url');
    }
    if (identityProviderUrl) {
      sessionStorage.setItem('aztrix:web-id:identity_provider_url', identityProviderUrl);
    } else {
      sessionStorage.removeItem('aztrix:web-id:identity_provider_url');
    }

    combineLatest([
      this._generateChallengeFromCodeVerifier$(codeVerifier),
      this._session
        .getAuthorizationUrl(webIdUrl, identityProviderUrl, 'response')
        .pipe(map((response) => response.headers.get('location'))),
    ]).subscribe(([codeChallenge, redirectUrl]) => {
      const url = new URL(redirectUrl || '');
      if (isDevMode()) {
        url.searchParams.set('redirect_url', 'replace');
      }
      url.searchParams.set('code_challenge', codeChallenge);
      window.location.assign(url.toString());
    });
  }

  private _generateCodeVerifier() {
    const array = new Uint32Array(14);
    window.crypto.getRandomValues(array);
    return Array.from(array, (v) => {
      const decString = '0' + v.toString(16);
      return decString.substring(0, decString.length - 2);
    }).join('');
  }

  _generateChallengeFromCodeVerifier$(codeVerifier: string): Observable<string> {
    return from(this._generateChallengeFromCodeVerifier(codeVerifier));
  }

  async _generateChallengeFromCodeVerifier(codeVerifier: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const hashed = await window.crypto.subtle.digest('SHA-256', data);
    let str = '';
    const bytes = new Uint8Array(hashed);
    const length = bytes.byteLength;
    for (let i = 0; i < length; i++) {
      str += String.fromCharCode(bytes[i]);
    }

    return btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  }
}
