<ax-auth-form-header
  [title]="'label.login'"
  [subTexts]="['register.individual.complete.title' | translate]"
  [logo]="logo"
></ax-auth-form-header>

<ax-snackbar
  *ngIf="errorType !== ErrorType.NONE && errorType !== ErrorType.LOGIN_NOT_VERIFIED"
  [dismissible]="false"
  color="red"
>
  <ng-container [ngSwitch]="errorType">
    <span *ngSwitchCase="ErrorType.FORBIDDEN">{{ 'error.too-many-attempts' | translate }}</span>
    <span *ngSwitchCase="ErrorType.INVALID_LOGIN">{{
      'error.login.invalid.login' | translate
    }}</span>
    <span *ngSwitchCase="ErrorType.LOGIN_NOT_REGISTERED">{{
      'error.login.invalid.login' | translate
    }}</span>
    <span *ngSwitchCase="ErrorType.LOGIN_INVALID_PASSWORD">{{
      'error.login.invalid.password' | translate
    }}</span>
    <span *ngSwitchCase="ErrorType.INVALID_TOKEN">{{ 'register.invalid.token' | translate }}</span>
    <span *ngSwitchDefault>{{ 'error.default.title' | translate }}</span>
  </ng-container>
</ax-snackbar>

<form class="login-form form" name="login" [formGroup]="form" (ngSubmit)="_login()">
  <div *ngIf="errorType !== ErrorType.LOGIN_NOT_VERIFIED">
    <div class="value">
      <ax-value-edit
        class="input"
        *ngIf="!showMobile"
        [form]="emailForm"
        [label]="'EMAIL' | propertyTypeLabel"
        type="EMAIL"
        name="login username"
        [errorsTemplate]="errorsTemplate"
        [autofocus]="true"
      ></ax-value-edit>
      <ax-value-edit
        class="input"
        *ngIf="showMobile"
        [form]="mobileForm"
        [label]="'PHONE' | propertyTypeLabel"
        type="PHONE"
        name="login username"
        [errorsTemplate]="errorsTemplate"
        [autofocus]="true"
      ></ax-value-edit>
      <button
        class="link"
        axButton
        *ngIf="allowMobile"
        type="button"
        (click)="switchMobileOrEmail()"
      >
        {{ 'login.use-' + (showMobile ? 'email' : 'mobile') | translate }}
      </button>
    </div>

    <div class="value">
      <ax-password-edit
        class="input"
        [form]="passwordForm"
        name="login password"
        [showToggle]="true"
      ></ax-password-edit>
      <button class="link" axButton type="button" (click)="modeService.setMode('reset')">
        {{ 'login.forgot.password' | translate }}
      </button>
    </div>
  </div>

  <ax-button
    class="action"
    filled
    fullWidth
    type="submit"
    color="accent"
    [loading]="loading$ | async"
    (click)="_login()"
    [ariaLabel]="'label.login' | translate"
  >
    {{ 'label.login' | translate }}
  </ax-button>
</form>

<ax-mode-switch></ax-mode-switch>

<ng-template #errorsTemplate>
  <ax-login-value-form-error
    *ngIf="emailForm.touched && !showMobile"
    [form]="emailForm"
    [propertyType]="'EMAIL'"
  ></ax-login-value-form-error>
  <ax-login-value-form-error
    *ngIf="mobileForm.touched && showMobile"
    [form]="mobileForm"
    [propertyType]="'MOBILE_PHONE'"
  ></ax-login-value-form-error>
</ng-template>
