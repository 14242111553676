<ng-container [ngSwitch]="step">
  <ax-auth-form-header
    *ngSwitchCase="0"
    title="register.title"
    [subTexts]="['register.individual.complete.title' | translate]"
    [logo]="logo"
  ></ax-auth-form-header>
  <ax-auth-form-header
    *ngSwitchCase="1"
    [title]="alistClaimCode ? 'register.claim.title' : 'register.title'"
    [subTexts]="
      alistClaimCode
        ? ['register.auth.claim.subtext' | translate, 'register.auth.subtext' | translate]
        : ['register.auth.subtext' | translate]
    "
    [logo]="logo"
  ></ax-auth-form-header>
  <ax-auth-form-header
    *ngSwitchCase="2"
    title="label.register"
    [subTexts]="[
      'register.individual.basicInfo.birthday.explanation' | translate: {age: REGISTER_MINIMUM_AGE}
    ]"
    [logo]="logo"
  ></ax-auth-form-header>
  <ax-auth-form-header
    *ngSwitchCase="3"
    title="register.account.title"
    [subTexts]="[
      'register.account.subtext' | translate,
      'register.account-type.explanation' | translate
    ]"
    [logo]="logo"
  ></ax-auth-form-header>
  <ax-auth-form-header
    *ngSwitchCase="4"
    title="register.account.title"
    [subTexts]="['register.account.subtext' | translate]"
    [logo]="logo"
  ></ax-auth-form-header>
</ng-container>

<ax-snackbar class="error" *ngIf="error$ | async as error" color="red"
  ><span>{{
    errorMessage$
      | async
      | translate
        : {
            propertyType: 'property.EMAIL.label' | translate | lowercase,
            loginValue: propertiesForm?.get('EMAIL')?.value.value
          }
  }}</span></ax-snackbar
>

<div class="register-form form" [ngClass]="{category: step === 3}" [ngSwitch]="step">
  <ax-register-claim-step
    *ngSwitchCase="0"
    [form]="form"
    [claim]="claim"
    (continue)="continue()"
  ></ax-register-claim-step>
  <ax-register-authentication-step
    *ngSwitchCase="1"
    [form]="form"
    [hasBackButton]="!alistClaimCode"
    (back)="back()"
    (continue)="continue()"
  ></ax-register-authentication-step>
  <ax-register-user-data-step
    *ngSwitchCase="2"
    [form]="form"
    (back)="back()"
    (continue)="continue()"
  ></ax-register-user-data-step>
  <ax-register-account-type-step
    *ngSwitchCase="3"
    [form]="form"
    [individualRegisterAllowed]="individualRegisterAllowed"
    (back)="back()"
    (continue)="continue()"
  ></ax-register-account-type-step>
  <ng-container *ngSwitchCase="4">
    <ax-register-individual-step
      *ngIf="typeForm?.value === ProfileContext.INDIVIDUAL"
      [form]="form"
      [loading$]="loading$"
      (back)="back()"
      (continue)="continue()"
    ></ax-register-individual-step>
    <ax-register-organisation-step
      *ngIf="typeForm?.value === ProfileContext.ORGANISATION"
      [form]="form"
      [loading$]="loading$"
      (back)="back()"
      (continue)="continue()"
    ></ax-register-organisation-step>
  </ng-container>
</div>
<ax-mode-switch *ngIf="alistClaimCode ? step === 1 : step === 0"></ax-mode-switch>
