import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {APP_INITIALIZER, ModuleWithProviders, NgModule, Provider} from '@angular/core';
import {browserCountryCodeRep, DEFAULT_COUNTRY} from '@aztrix/helpers';
import {BehaviorSubject} from 'rxjs';

import {AuthComponent} from './auth.component';
import {AuthFormHeaderComponent} from './components/auth-form-header/auth-form-header.component';
import {LoginFormComponent} from './components/login-form/login-form.component';
import {ModeSwitchComponent} from './components/mode-switch/mode-switch.component';
import {OtpLoginEmailStepComponent} from './components/otp-login-form/otp-login-email-step/otp-login-email-step.component';
import {OtpLoginFormComponent} from './components/otp-login-form/otp-login-form.component';
import {OtpLoginVerificationStepComponent} from './components/otp-login-form/otp-login-verification-step/otp-login-verification-step.component';
import {RegisterAccountTypeStepComponent} from './components/register-form/register-account-type-step/register-account-type-step.component';
import {RegisterAuthenticationStepComponent} from './components/register-form/register-authentication-step/register-authentication-step.component';
import {RegisterClaimStepComponent} from './components/register-form/register-claim-step/register-claim-step.component';
import {RegisterFormComponent} from './components/register-form/register-form.component';
import {RegisterIndividualStepComponent} from './components/register-form/register-individual-step/register-individual-step.component';
import {RegisterOrganisationStepComponent} from './components/register-form/register-organisation-step/register-organisation-step.component';
import {RegisterUserDataStepComponent} from './components/register-form/register-user-data-step/register-user-data-step.component';
import {ResetPasswordEmailStepComponent} from './components/reset-password-form/reset-password-email-step/reset-password-email-step.component';
import {ResetPasswordFormComponent} from './components/reset-password-form/reset-password-form.component';
import {ResetPasswordNewPasswordStepComponent} from './components/reset-password-form/reset-password-new-password-step/reset-password-new-password-step.component';
import {StepSwitchComponent} from './components/step-switch/step-switch.component';
import {VerificationFormComponent} from './components/verification-form/verification-form.component';
import {VerificationStepComponent} from './components/verification-form/verification-step/verification-step.component';
import {VerificationSuccessStepComponent} from './components/verification-form/verification-success-step/verification-success-step.component';
import {WebidLoginFormComponent} from './components/webid-login-form/webid-login-form.component';
import {AuthInterceptor} from './interceptors/auth.interceptor';
import {ModeService} from './services/mode.service';
import {VerificationService} from './services/verification.service';
import {AuthService} from './store/auth.service';
import {AuthSettingsService} from './store/auth-settings.service';
import {WEB_ID_ENABLED} from './web_id_enabled';

const STEP_COMPONENTS = [
  RegisterClaimStepComponent,
  RegisterAuthenticationStepComponent,
  RegisterUserDataStepComponent,
  RegisterAccountTypeStepComponent,
  RegisterIndividualStepComponent,
  RegisterOrganisationStepComponent,
  ResetPasswordNewPasswordStepComponent,
  ResetPasswordEmailStepComponent,
  VerificationStepComponent,
  VerificationSuccessStepComponent,
  OtpLoginEmailStepComponent,
  OtpLoginVerificationStepComponent,
];

@NgModule({
  imports: [
    AuthComponent,
    LoginFormComponent,
    RegisterFormComponent,
    ResetPasswordFormComponent,
    VerificationFormComponent,
    OtpLoginFormComponent,
    WebidLoginFormComponent,
    ModeSwitchComponent,
    StepSwitchComponent,
    AuthFormHeaderComponent,
    ...STEP_COMPONENTS,
  ],
  exports: [AuthComponent],
})
export class AuthModule {
  static forRoot(webIdEnabled = false): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: provideAuth(webIdEnabled),
    };
  }
}

export function authSettingsServiceFactory(service: AuthSettingsService) {
  return () => service.init();
}

export function provideAuth(webIdEnabled = false): Provider[] {
  return [
    AuthService,
    ModeService,
    VerificationService,
    {
      provide: APP_INITIALIZER,
      useFactory: authSettingsServiceFactory,
      deps: [AuthSettingsService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: DEFAULT_COUNTRY,
      useValue: new BehaviorSubject(browserCountryCodeRep()),
    },
    {
      provide: WEB_ID_ENABLED,
      useValue: webIdEnabled,
    },
  ];
}
