<ax-auth-form-header
  [title]="'label.login'"
  [subTexts]="['register.individual.complete.title' | translate]"
  [logo]="logo"
></ax-auth-form-header>

<div class="login-form form">
  <p>{{ 'settings.web-id.providers.select' | translate }}</p>

  <div class="providers">
    <button
      *ngFor="let provider of providers"
      axButton
      color="accent"
      filled
      (click)="initiate({identityProviderUrl: provider.url})"
      [disabled]="!!provider.disabled"
    >
      {{ provider.name }}
    </button>
  </div>

  <!-- TODO: enable when other providers than inrupt are possible -->
  <!-- <span class="or">{{ 'label.or' | translate }}</span>

  <div class="custom">
    <div class="value">
      <ax-value-edit
        [type]="'URL'"
        [label]="
          (urlType === 'webid'
            ? 'settings.web-id.input.label'
            : 'settings.web-id.input.provider.label'
          ) | translate
        "
        [form]="urlForm"
      ></ax-value-edit>
      <button class="link" axButton type="button" (click)="switchType()">
        {{ 'settings.web-id.use-' + (urlType === 'webid' ? 'provider' : 'webid') | translate }}
      </button>
    </div>
    <button class="go" axButton filled color="accent" (click)="initiateUrl()">
      {{ 'label.go' | translate }}
    </button>
  </div> -->
</div>

<div class="create">
  <span>{{ 'settings.web-id.no-webid' | translate }}</span>
  <button class="link" axButton type="button" (click)="initiate({})">
    {{ 'settings.web-id.create-new' | translate }}
  </button>
</div>
