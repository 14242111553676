import {LowerCasePipe} from '@angular/common';
import {Component, Input} from '@angular/core';
import {OtpVerificationModule} from '@aztrix/components/otp-verification';
import {TranslatePipe} from '@aztrix/translate';
import {Observable} from 'rxjs';

import {AbstractOtpLogin} from '../abstract-otp-login-form';

@Component({
  selector: 'ax-otp-login-verification-step',
  templateUrl: './otp-login-verification-step.component.html',
  styleUrls: ['./otp-login-verification-step.component.scss', '../../form.scss'],
  standalone: true,
  imports: [OtpVerificationModule, LowerCasePipe, TranslatePipe],
})
export class OtpLoginVerificationStepComponent extends AbstractOtpLogin {
  @Input() verification: (code: string, verificationValue: string) => Observable<any>;
  @Input() retryVerification: (verificationValue: string) => Observable<any>;
}
