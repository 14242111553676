<ax-auth-form-header
  title="label.login"
  [subTexts]="['login.otp.explanation' | translate]"
  [logo]="logo"
></ax-auth-form-header>

<div class="form">
  <ax-otp-login-email-step
    *ngIf="step === 0"
    [form]="form"
    (continue)="continue()"
  ></ax-otp-login-email-step>
  <ax-otp-login-verification-step
    *ngIf="step === 1"
    [form]="form"
    [verification]="verification"
    [retryVerification]="retryVerification"
  ></ax-otp-login-verification-step>
  <ax-verification-success-step *ngIf="step === 2"></ax-verification-success-step>
</div>
