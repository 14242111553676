import {NgIf} from '@angular/common';
import {Component} from '@angular/core';
import {ButtonModule} from '@aztrix/components/button';
import {LoginValueFormErrorModule} from '@aztrix/components/login-value-form-error';
import {ValueEditModule} from '@aztrix/components/value-edit';
import {TranslatePipe} from '@aztrix/translate';

import {AbstractResetPasswordStep} from '../abstract-reset-password-step';
import {HelperPipesModule} from '@aztrix/helpers';

@Component({
  selector: 'ax-reset-password-email-step',
  templateUrl: './reset-password-email-step.component.html',
  styleUrls: ['./reset-password-email-step.component.scss', '../../form.scss', '../../link.scss'],
  standalone: true,
  imports: [
    NgIf,
    ValueEditModule,
    ButtonModule,
    LoginValueFormErrorModule,
    HelperPipesModule,
    TranslatePipe,
  ],
})
export class ResetPasswordEmailStepComponent extends AbstractResetPasswordStep {}
