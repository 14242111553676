import {AsyncPipe, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault} from '@angular/common';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ButtonModule} from '@aztrix/components/button';
import {LoginValueFormErrorModule} from '@aztrix/components/login-value-form-error';
import {PasswordEditModule} from '@aztrix/components/password-edit';
import {SnackbarModule} from '@aztrix/components/snackbar';
import {ValueEditModule} from '@aztrix/components/value-edit';
import {TranslatePipe, TranslateService} from '@aztrix/translate';
import {ErrorType} from '@aztrix/models';
import {SessionsService} from '@aztrix/sdk';
import {FormControl} from '@ngneat/reactive-forms';
import {BehaviorSubject, first, map} from 'rxjs';

import {ModeService} from '../../services/mode.service';
import {AuthFormHeaderComponent} from '../auth-form-header/auth-form-header.component';
import {ModeSwitchComponent} from '../mode-switch/mode-switch.component';
import {CustomValidators, HelperPipesModule} from '@aztrix/helpers';

@Component({
  selector: 'ax-login-form',
  templateUrl: 'login-form.component.html',
  styleUrls: ['login-form.component.scss', '../form.scss', '../link.scss'],
  standalone: true,
  imports: [
    AuthFormHeaderComponent,
    NgIf,
    SnackbarModule,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    FormsModule,
    ReactiveFormsModule,
    ValueEditModule,
    ButtonModule,
    PasswordEditModule,
    ModeSwitchComponent,
    LoginValueFormErrorModule,
    AsyncPipe,
    HelperPipesModule,
    TranslatePipe,
  ],
})
export class LoginFormComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() allowMobile = false;
  @Input() logo = 'custom:aztrixlogo';
  @Output() loginComplete = new EventEmitter<{authToken: string; password: string}>();

  loading$ = new BehaviorSubject(false);

  ErrorType = ErrorType;

  errorType = ErrorType.NONE;
  showMobile = false;

  mobileForm = new FormControl('', [CustomValidators.validateLoginValue()]);
  emailForm = new FormControl('', [CustomValidators.validateLoginValue()]);

  constructor(
    public modeService: ModeService,
    private _sessions: SessionsService,
    private _snackbar: MatSnackBar,
    private _translate: TranslateService
  ) {}

  get usernameForm() {
    return this.form.get('login');
  }

  get passwordForm() {
    return <FormControl<string>>this.form.get('password');
  }

  ngOnInit(): void {
    this.mobileForm.valueChanges.subscribe((value) => {
      this.usernameForm?.setValue(value);
    });
    this.emailForm.valueChanges.subscribe((value) => {
      this.usernameForm?.setValue(value);
    });
  }

  switchMobileOrEmail() {
    if (this.showMobile) {
      this.usernameForm?.setValue(this.emailForm.value);
    } else {
      this.usernameForm?.setValue(this.mobileForm.value);
    }
    this.showMobile = !this.showMobile;
  }

  _login() {
    this.errorType = ErrorType.NONE;

    this.form.disable();
    if (this.loading$.value === false) {
      this.loading$.next(true);
      this._sessions
        .login(this.form.value, 'response')
        .pipe(
          first(),
          map((resp) => resp.headers.get('AuthToken')),
          map((authToken) => authToken || undefined)
        )
        .subscribe({
          next: (authToken: string | undefined) => {
            this.loading$.next(false);
            this.form.enable();
            this.loginComplete.next({
              authToken: <string>authToken,
              password: this.passwordForm?.value,
            });
          },
          error: (response) => {
            this.errorType = response.error;
            if (response.error === ErrorType.LOGIN_INVALID_PASSWORD) {
              this._clearPassword();
            }

            if (response.error === ErrorType.LOGIN_NOT_VERIFIED) {
              this._snackbar.open(this._translate.instant('error.login.not-verified'), undefined, {
                duration: 5000,
              });
              this.modeService.password = this.passwordForm?.value;
              this.modeService.setMode('verification', {
                verificationValue: this.usernameForm?.value,
              });
            }

            this.loading$.next(false);
            this.form.enable();
          },
        });
    }
  }

  private _clearPassword() {
    this.passwordForm?.reset();
    this.passwordForm?.markAsUntouched();
  }
}
