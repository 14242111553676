import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import {ForgotPasswordModalComponent} from '@aztrix/components/forgot-password-modal';
import {OverlayService} from '@aztrix/components/overlay';
import {ValueEdit} from '@aztrix/components/value-edit';
import {Subscription} from 'rxjs';

@Component({
  selector: 'ax-password-edit',
  templateUrl: './password-edit.component.html',
  styleUrls: ['./password-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordEditComponent extends ValueEdit implements OnChanges, OnDestroy {
  @Input() username: string;
  @Input() showToggle: boolean;
  @Input() forgotPasswordHint = false;
  @Input() saveInPasswordManager = true;
  @Input() minLength = 8;

  subscription = new Subscription();

  constructor(
    private _overlay: OverlayService,
    private _elementRef: ElementRef,
    changeDetector: ChangeDetectorRef
  ) {
    super(changeDetector);
  }

  passwordVisible: boolean;

  override ngOnChanges(changes: SimpleChanges) {
    if (changes.form && this.form) {
      this.subscription.unsubscribe();
      this.subscription = this.form.statusChanges.subscribe(() => {
        this._changeDetector.markForCheck();
      });
    }

    super.ngOnChanges(changes);
  }

  override ngOnDestroy() {
    this.subscription.unsubscribe();
    super.ngOnDestroy();
  }

  openForgotPasswordModal(): void {
    this._overlay.createModal(this._elementRef, ForgotPasswordModalComponent, {
      title: 'modal.forgotPassword.title',
      init: (modal) => {
        modal.username = this.username;
      },
    });
  }

  get errors() {
    return Object.keys(this.form?.errors || []);
  }

  errorTranslationKey(errorType: string): string {
    switch (errorType) {
      case 'minlength':
      case 'required':
        return 'password.format.requirement';
      case 'match':
        return 'password.format.match';
      case 'invalid':
        return 'password.incorrect';
      case 'same':
        return 'password.format.same';
      default:
        console.warn(`unknown errorType: ${errorType}`);
        return 'password.format.requirement';
    }
  }
}
