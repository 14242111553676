import {Inject, Injectable, Optional} from '@angular/core';
import {
  browserCountryCodeRep,
  browserLocale,
  CountryCodeRepresentation,
  DEFAULT_COUNTRY,
} from '@aztrix/helpers';
import {Locale} from '@aztrix/models';
import {AUTH_TOKEN} from '@aztrix/sdk';
import {Store} from '@ngxs/store';
import {BehaviorSubject} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';

import {CheckAuthenticated, InvalidateSession, Logout, SetAuthToken} from './auth.actions';
import {AuthState} from './auth.state';
import {LOCALE} from '@aztrix/translate';

@Injectable({providedIn: 'root'})
export class AuthService {
  authToken$ = this._store.select(AuthState.authToken);

  loggedIn$ = this.authToken$.pipe(
    map((authToken) => !!authToken),
    shareReplay(1)
  );
  loggedOut$ = this.loggedIn$.pipe(
    map((loggedIn) => !loggedIn),
    shareReplay(1)
  );

  constructor(
    private _store: Store,
    @Optional() @Inject(AUTH_TOKEN) authToken$: BehaviorSubject<string | undefined>,
    @Optional()
    @Inject(DEFAULT_COUNTRY)
    private _defaultCountry$: BehaviorSubject<CountryCodeRepresentation>,
    @Optional() @Inject(LOCALE) private _locale$: BehaviorSubject<Locale>
  ) {
    if (this._locale$) {
      this._locale$.next(browserLocale());
    }

    this.authToken$.subscribe((authToken) => {
      authToken$.next(authToken);
    });
  }

  setAuthToken(authToken: string) {
    return this._store.dispatch(new SetAuthToken(authToken));
  }

  logout() {
    this._defaultCountry$.next(browserCountryCodeRep());
    if (this._locale$) {
      this._locale$.next(browserLocale());
    }
    return this._store.dispatch(Logout);
  }

  checkAuthenticated() {
    return this._store.dispatch(CheckAuthenticated);
  }

  invalidateSession() {
    return this._store.dispatch(InvalidateSession);
  }
}
