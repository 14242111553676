import {HttpErrorResponse} from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {ActionFinishedComponent} from '@aztrix/components/action-finished';
import {OverlayService} from '@aztrix/components/overlay';
import {CustomValidators, PropertyFormControl} from '@aztrix/helpers';
import {ErrorType, PropertyType} from '@aztrix/models';
import {RequestPasswordResetRepresentation, SessionsService} from '@aztrix/sdk';

@Component({
  templateUrl: './forgot-password-modal.component.html',
  styleUrls: ['./forgot-password-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordModalComponent implements OnChanges {
  @Input() username: string;

  errorType = ErrorType.NONE;

  form = PropertyFormControl.createByType(PropertyType.EMAIL, [
    CustomValidators.propertyRequired,
    CustomValidators.propertyValid,
  ]);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  ErrorType = ErrorType;

  constructor(
    private _changeDetector: ChangeDetectorRef,
    private _overlay: OverlayService,
    private _elementRef: ElementRef,
    private _sessions: SessionsService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.username && this.username) {
      this.form.get('value')?.setValue(this.username);
    }
  }

  executeSendAction(): void {
    this.errorType = ErrorType.NONE;
    this.form.disable();
    this._sessions
      .requestPasswordReset({
        loginType: RequestPasswordResetRepresentation.LoginTypeEnum.EMAIL,
        loginValue: this.form.get('value')?.value,
      })
      .subscribe({
        next: (res) => this.openPasswordResetPendingModal(res.loginType),
        error: (response: HttpErrorResponse) => {
          this.errorType = response.error in ErrorType ? response.error : ErrorType.DEFAULT;
          this.form.enable();
        },
      });
  }

  resetErrors(): void {
    this.errorType = ErrorType.NONE;
    this._changeDetector.markForCheck();
  }

  openPasswordResetPendingModal(propertyType?: PropertyType): void {
    const type = propertyType === PropertyType.EMAIL ? 'email' : 'mobile';
    this._overlay.closeModal();
    this._overlay.createModal(this._elementRef, ActionFinishedComponent, {
      title: 'modal.forgotPassword.title',
      init: (resetModal) => {
        resetModal.bodyIconClass = type === 'mobile' ? 'message' : 'email';
        resetModal.bodyTitle = `resetPassword.succeeded.${type}.title`;
        resetModal.bodyExplanation = `resetPassword.succeeded.${type}.explanation`;
        resetModal.didClickButton.subscribe(() => this._overlay.closeModal());
      },
    });
  }
}
