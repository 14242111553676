import {AsyncPipe, NgSwitch, NgSwitchCase} from '@angular/common';
import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {ButtonModule} from '@aztrix/components/button';
import {ContrastModule} from '@aztrix/components/contrast';
import {TranslatePipe} from '@aztrix/translate';
import {Subscription} from 'rxjs';

import {ModeService} from '../../services/mode.service';

@Component({
  selector: 'ax-mode-switch',
  templateUrl: './mode-switch.component.html',
  styleUrls: ['./mode-switch.component.scss', '../link.scss'],
  standalone: true,
  imports: [ContrastModule, NgSwitch, NgSwitchCase, ButtonModule, AsyncPipe, TranslatePipe],
})
export class ModeSwitchComponent implements OnInit, OnDestroy {
  @HostBinding('class') mode: 'login' | 'register' | 'reset' | 'verification';

  mode$ = this.modeService.mode$;

  private _subscription = new Subscription();

  constructor(public modeService: ModeService) {}

  ngOnInit(): void {
    this._subscription = this.mode$.subscribe((mode) => {
      this.mode = mode;
    });
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
