import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ActionFinishedModule} from '@aztrix/components/action-finished';
import {ButtonModule} from '@aztrix/components/button';
import {LoginErrorsModule} from '@aztrix/components/login-errors';
import {PropertyEditModule} from '@aztrix/components/property-edit';
import {TranslatePipe} from '@aztrix/translate';
import {IconsModule} from '@aztrix/icons';

import {ForgotPasswordModalComponent} from './forgot-password-modal.component';

@NgModule({
  declarations: [ForgotPasswordModalComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    IconsModule,
    ReactiveFormsModule,
    LoginErrorsModule,
    TranslatePipe,
    ButtonModule,
    ActionFinishedModule,
    PropertyEditModule,
  ],
  exports: [ForgotPasswordModalComponent],
})
export class ForgotPasswordModalModule {}
