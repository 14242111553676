import {AsyncPipe, NgIf} from '@angular/common';
import {Component, Inject, Input} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCheckbox} from '@angular/material/checkbox';
import {MatError} from '@angular/material/form-field';
import {ButtonModule} from '@aztrix/components/button';
import {PasswordEditModule} from '@aztrix/components/password-edit';
import {PropertyEditModule} from '@aztrix/components/property-edit';
import {BACKEND_URL} from '@aztrix/environment';
import {TranslatePipe, TranslateService} from '@aztrix/translate';
import {IconComponent} from '@aztrix/icons';
import {PropertyRepresentation} from '@aztrix/sdk';
import {BehaviorSubject, combineLatest, map} from 'rxjs';

import {StepSwitchComponent} from '../../step-switch/step-switch.component';
import {AbstractRegisterStep} from '../abstract-register-step';

@Component({
  selector: 'ax-register-authentication-step',
  templateUrl: './register-authentication-step.component.html',
  styleUrls: ['./register-authentication-step.component.scss', '../../form.scss'],
  standalone: true,
  imports: [
    PropertyEditModule,
    PasswordEditModule,
    MatCheckbox,
    FormsModule,
    ReactiveFormsModule,
    IconComponent,
    MatError,
    NgIf,
    ButtonModule,
    StepSwitchComponent,
    AsyncPipe,
    TranslatePipe,
  ],
})
export class RegisterAuthenticationStepComponent extends AbstractRegisterStep {
  @Input() hasBackButton = true;

  termsUrl$ = combineLatest([this._backendUrl, this._translate.lang$]).pipe(
    map(([url, lang]) => url + `/policy?type=terms_conditions&lang=${lang}`)
  );
  privacyUrl$ = combineLatest([this._backendUrl, this._translate.lang$]).pipe(
    map(([url, lang]) => url + `/policy?type=privacy_policy_preface&lang=${lang}`)
  );

  constructor(
    private _translate: TranslateService,
    @Inject(BACKEND_URL) private _backendUrl: BehaviorSubject<string>
  ) {
    super();
  }

  get emailForm() {
    return this.propertiesForm?.get(PropertyRepresentation.TypeEnum.EMAIL);
  }

  get passwordForm() {
    return this.form.get('password');
  }

  get termsForm() {
    return this.legalForm?.get('terms');
  }

  get privacyForm() {
    return this.legalForm?.get('privacy');
  }

  get newsletterForm() {
    return this.legalForm?.get('newsletter');
  }
}
