import {NgClass, NgFor} from '@angular/common';
import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatRadioButton, MatRadioGroup} from '@angular/material/radio';
import {TranslatePipe} from '@aztrix/translate';
import {ProfileContext, PropertyType} from '@aztrix/models';

import {StepSwitchComponent} from '../../step-switch/step-switch.component';
import {AbstractRegisterStep} from '../abstract-register-step';

@Component({
  selector: 'ax-register-account-type-step',
  templateUrl: './register-account-type-step.component.html',
  styleUrls: ['./register-account-type-step.component.scss', '../../form.scss'],
  standalone: true,
  imports: [
    MatRadioGroup,
    FormsModule,
    ReactiveFormsModule,
    NgFor,
    MatRadioButton,
    NgClass,
    StepSwitchComponent,
    TranslatePipe,
  ],
})
export class RegisterAccountTypeStepComponent extends AbstractRegisterStep implements OnInit {
  @Input() individualRegisterAllowed = true;
  selectionForm = new FormControl();

  options = ['personal', 'professional', 'company', 'non-profit'];

  ngOnInit() {
    if (!this.individualRegisterAllowed) {
      this.options = ['professional', 'company', 'non-profit'];
    }
    this.typeForm?.markAsTouched();
    this.organisationTypeForm?.get('value')?.markAsTouched();
    if (this.typeForm?.value === ProfileContext.INDIVIDUAL) {
      this.selectionForm.setValue('personal');
    } else {
      this.selectionForm.setValue(this.organisationTypeForm?.get('value')?.value || 'professional');
    }

    this.selectionForm.valueChanges.subscribe((value) => {
      const valueForm = this.organisationTypeForm?.get('value');
      if (value === 'personal') {
        this.typeForm?.setValue(ProfileContext.INDIVIDUAL);
        valueForm?.setValue('');
        valueForm?.markAsUntouched();
      } else {
        this.typeForm?.setValue(ProfileContext.ORGANISATION);
        valueForm?.setValue(value);
      }
    });
  }

  get typeForm() {
    return this.form.get('type');
  }

  get organisationTypeForm() {
    return this.propertiesForm?.get(PropertyType.ORGANISATION_TYPE);
  }
}
